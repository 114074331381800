<template>
  <div class="stuff pt40 plr40">
    <my-header
      type="search"
      v-model="word"
      @search="onSearch"
      style="margin-right: 35px;"
    ></my-header>
    <div class="banner-container">
      <div style="width: 100%">
        <swiper :options="swiperOptions" v-if="bannerData.length">
          <swiper-slide v-for="(item, index) in bannerData" :key="index">
            <div
              style="width: 100%; cursor: pointer;"
              @click="going(item, index)"
            >
              <div style="width: 100%">
                <img :src="item.img" alt="" :style="'width:50%;height:auto'" />
              </div>
              <div class="fweight pt15 sptitle">
                <div class="f20 c333">{{ item.subtitle }}</div>
                <div class="f28 c333 pt10">{{ item.name }}</div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)
import myHeader from '../components/head.vue'

export default {
  name: 'stuff',
  components: {
    myHeader
  },
  data() {
    var that = this
    return {
      word: '',
      bannerData: [],
      swiperOptions: {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
        // on: {
        //   click: function() {
        //     const realIndex = this.realIndex
        //     // console.log(realIndex)
        //     // that.going(that.bannerData[realIndex])
        //   }
        // }
      },
      screenHeight: document.documentElement.clientHeight
    }
  },
  mounted() {
    window.addEventListener('resize', this.getWindow)
    this.getBanner()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindow)
  },
  methods: {
    getWindow() {
      this.screenHeight = document.body.offsetHeight
    },
    async getBanner() {
      var params = {
        page: 1,
        pagesize: 40
      }
      var res = await this.$api.get('/api/project_course/sujectList', params)
      if (res.code == 0) {
        var data = res.data.list
        this.bannerData = data
      }
    },
    onSearch() {
      if (!this.word) return this.$message.warning('关键词不能为空')
      this.$router.push({ name: 'stuff-list', params: { value: this.word } })
    },
    going(item) {
      this.$router.push('/stuff/search?id=' + item.id + '&keyword=' + item.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.stuff {
  height: 100%;
  .banner-container {
    position: relative;
    width: 80%;
    height: calc(100% - 90px);
    margin: 0 auto;
    // margin-top: 40px;
    display: flex;
    align-items: center;
    img {
      height: 300px;
    }
    .fweight {
      & > div {
        font-weight: bold;
        position: relative;
        top: -14px;
      }
    }
    .swiper-button-prev {
      background-image: url(../assets/img/left.png);
      background-size: 70px 70px;
      width: 70px;
      height: 70px;
      left: -90px;
    }
    .swiper-button-next {
      background-image: url(../assets/img/right.png);
      background-size: 70px 70px;
      width: 70px;
      height: 70px;
      right: -90px;
    }
    img {
      width: 300px;
      vertical-align: middle;
      cursor: pointer;
    }
  }
}
</style>
